import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "40",
  height: "40",
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", null, [
      _createElementVNode("path", {
        id: "Mobile",
        d: "M32.0917 8.83312C31.9363 7.51873 31.3387 6.29613 30.3967 5.36633C29.4548 4.43652 28.2247 3.85467 26.9084 3.71634C22.317 3.20523 17.683 3.20523 13.0916 3.71634C11.7754 3.85467 10.5452 4.43652 9.60328 5.36633C8.66136 6.29613 8.06369 7.51873 7.90833 8.83312C7.63506 11.1564 7.49872 13.4937 7.50001 15.833V24.1664C7.49872 26.5057 7.63506 28.843 7.90833 31.1663C8.06369 32.4807 8.66136 33.7033 9.60328 34.6331C10.5452 35.5629 11.7754 36.1447 13.0916 36.2831C17.683 36.7942 22.317 36.7942 26.9084 36.2831C28.2247 36.1447 29.4548 35.5629 30.3967 34.6331C31.3387 33.7033 31.9363 32.4807 32.0917 31.1663C32.365 28.843 32.5013 26.5057 32.5 24.1664V15.833C32.5013 13.4937 32.365 11.1564 32.0917 8.83312ZM30.1583 24.1664C30.1571 26.4137 30.0263 28.6592 29.7667 30.8914C29.6625 31.6765 29.2995 32.4044 28.735 32.9599C28.1704 33.5154 27.4367 33.8666 26.65 33.958C22.2191 34.4494 17.7475 34.4494 13.3167 33.958C12.53 33.8666 11.7962 33.5154 11.2317 32.9599C10.6672 32.4044 10.3041 31.6765 10.2 30.8914C9.94034 28.6592 9.8096 26.4137 9.80836 24.1664V15.833C9.8096 13.5857 9.94034 11.3403 10.2 9.10798C10.3041 8.32288 10.6672 7.59497 11.2317 7.0395C11.7962 6.48403 12.53 6.1328 13.3167 6.04137C17.7475 5.55 22.2191 5.55 26.65 6.04137C27.4367 6.1328 28.1704 6.48403 28.735 7.0395C29.2995 7.59497 29.6625 8.32288 29.7667 9.10798C30.0263 11.3403 30.1571 13.5857 30.1583 15.833V24.1664ZM22.5 29.1664C22.5 29.6608 22.3534 30.1442 22.0787 30.5553C21.804 30.9665 21.4135 31.2869 20.9566 31.4761C20.4998 31.6654 19.9973 31.7148 19.5123 31.6184C19.0274 31.5219 18.5819 31.2838 18.2322 30.9342C17.8826 30.5845 17.6445 30.139 17.548 29.654C17.4516 29.1691 17.501 28.6666 17.6902 28.2098C17.8795 27.7529 18.1999 27.3624 18.6111 27.0877C19.0222 26.813 19.5056 26.6664 20 26.6664C20.6631 26.6664 21.299 26.9298 21.7678 27.3986C22.2366 27.8674 22.5 28.5033 22.5 29.1664Z",
        fill: "#110F0E",
        "fill-opacity": "0.54"
      })
    ], -1)
  ])))
}
export default { render: render }