import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "19",
  height: "19",
  fill: "none",
  viewBox: "0 0 19 19"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#110F0E",
      "fill-opacity": ".54",
      d: "m18.495 17.49-3.935-3.935c2.555-3.1 2.35-7.725-.59-10.67A8.033 8.033 0 0 0 8.275.515c-2.01 0-4.01.76-5.53 2.28-3.075 3.075-3.035 8.1.09 11.225a8.033 8.033 0 0 0 5.695 2.37c1.775 0 3.54-.595 4.975-1.78l3.93 3.93 1.06-1.05ZM3.89 12.975c-2.54-2.54-2.58-6.63-.09-9.12A6.287 6.287 0 0 1 8.28 2.01a6.51 6.51 0 0 1 4.64 1.935c2.54 2.54 2.58 6.63.09 9.12a6.287 6.287 0 0 1-4.48 1.845 6.51 6.51 0 0 1-4.64-1.935Z"
    }, null, -1)
  ])))
}
export default { render: render }