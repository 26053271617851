import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "18",
  fill: "none",
  viewBox: "0 0 20 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M14.68.5c-2.155 0-3.55 1.325-4.645 2.495A.054.054 0 0 1 10 3.01a.054.054 0 0 1-.035-.015C8.87 1.825 7.475.5 5.32.5 2 .5 0 3 0 6c0 5.755 5.175 9.705 8.08 11.465.59.355 1.255.535 1.92.535s1.33-.18 1.92-.535C14.825 15.705 20 11.76 20 6c0-3-2-5.5-5.32-5.5Z"
    }, null, -1)
  ])))
}
export default { render: render }