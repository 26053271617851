import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "21",
  height: "20",
  fill: "none",
  viewBox: "0 0 21 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M19.91 2.585a2 2 0 0 0-1.705-1.71 26.034 26.034 0 0 0-3.41-.245c-2.33 0-4.93.395-6.42 1.885L4.25 6.635A32.997 32.997 0 0 0 1.645 9.57a3.285 3.285 0 0 0 0 4.08 35.369 35.369 0 0 0 5.47 5.47 3.31 3.31 0 0 0 2.065.725 3.26 3.26 0 0 0 2-.7 33.718 33.718 0 0 0 2.93-2.61l4.12-4.12c2.37-2.325 2.025-7.34 1.68-9.83Zm-2.7 8.77-4.12 4.12a32.3 32.3 0 0 1-2.8 2.5c-.31.245-.695.378-1.09.375-.41 0-.806-.139-1.125-.395A33.44 33.44 0 0 1 2.84 12.72a1.795 1.795 0 0 1 0-2.215 31.5 31.5 0 0 1 2.5-2.8L9.465 3.58c.945-.945 2.8-1.445 5.36-1.445 1.072.006 2.143.083 3.205.23a.5.5 0 0 1 .425.43c.545 4.04.075 7.24-1.245 8.56Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M14.51 8.115a1.625 1.625 0 1 0 0-3.25 1.625 1.625 0 0 0 0 3.25Z"
    }, null, -1)
  ])))
}
export default { render: render }