import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "16",
  fill: "none",
  viewBox: "0 0 20 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M19.755 3.58a3.754 3.754 0 0 0-3.28-3.32A38.245 38.245 0 0 0 12 0H8C6.504 0 5.01.086 3.525.26a3.755 3.755 0 0 0-3.28 3.32 40.196 40.196 0 0 0 0 8.84 3.755 3.755 0 0 0 3.28 3.32C5.01 15.914 6.505 16 8 16h4c1.495 0 2.99-.086 4.475-.26a3.755 3.755 0 0 0 3.28-3.32 40.186 40.186 0 0 0 0-8.84ZM3.695 1.75A37.529 37.529 0 0 1 8 1.5h4c1.438 0 2.876.084 4.305.25a2.245 2.245 0 0 1 1.425.78l-3.135 3.13a38.643 38.643 0 0 1-3.175 2.845 2.28 2.28 0 0 1-1.42.5c-.5.003-.985-.164-1.375-.475a35.71 35.71 0 0 1-3.22-2.865L2.27 2.535c.36-.43.868-.71 1.425-.785Zm14.57 10.5a2.265 2.265 0 0 1-1.96 2A37.33 37.33 0 0 1 12 14.5H8c-1.439 0-2.876-.084-4.305-.25a2.265 2.265 0 0 1-1.96-2A38.5 38.5 0 0 1 1.7 4.075l2.645 2.64a37.97 37.97 0 0 0 3.35 3c.658.512 1.47.789 2.305.785a3.77 3.77 0 0 0 2.36-.825 39.068 39.068 0 0 0 3.295-2.955L18.3 4.08a38.505 38.505 0 0 1-.035 8.175v-.005Z"
    }, null, -1)
  ])))
}
export default { render: render }