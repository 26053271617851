import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M12 2C6.475 2 2 6.475 2 12s4.475 10 10 10 10-4.475 10-10S17.525 2 12 2Zm5.125 14.065-1.06 1.06L12 13.06l-4.065 4.065-1.06-1.06L10.94 12 6.875 7.935l1.06-1.06L12 10.94l4.065-4.065 1.06 1.06L13.06 12l4.065 4.065Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }