import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 20 21"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9 9.5a2 2 0 1 1-3.999.001A2 2 0 0 1 9 9.5Zm10.235 7.085c-.21 1.885-1.695 3.41-3.585 3.63-1.63.19-3.265.285-4.9.285h-1.5c-1.635 0-3.27-.095-4.895-.285C2.46 19.995.98 18.47.77 16.585a44.034 44.034 0 0 1 0-9.67c.21-1.885 1.695-3.41 3.585-3.63.38-.045.765-.085 1.145-.115V.5H7v2.565C7.75 3.02 8.5 3 9.25 3h1.5c.75 0 1.5.02 2.25.065V.5h1.5v2.67c.385.035.765.075 1.145.115 1.895.22 3.375 1.745 3.585 3.63.36 3.19.36 6.48.005 9.67Zm-1.49-9.505c-.135-1.2-1.09-2.17-2.27-2.305A41.454 41.454 0 0 0 10.75 4.5h-1.5c-1.575 0-3.16.095-4.725.275-1.18.14-2.135 1.11-2.27 2.305-.345 3.1-.345 6.24 0 9.335.135 1.2 1.09 2.17 2.27 2.305 1.56.185 3.15.275 4.725.275h1.5c1.57 0 3.16-.095 4.725-.275 1.18-.14 2.135-1.11 2.27-2.305a42.227 42.227 0 0 0 0-9.335Z"
    }, null, -1)
  ])))
}
export default { render: render }