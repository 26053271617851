import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "36",
  height: "30",
  fill: "none",
  viewBox: "0 0 36 30"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M18 29.5c2.61 0 5.245-1.291 5.68-1.291.435 0 1.62.846 3.558.846 10.16 0 11.5-22.663.933-28.308-.677-.362-1.467-.314-2.137.062-1.51.846-4.598 2.296-8.034 2.296-3.436 0-6.524-1.45-8.033-2.296C9.297.433 8.506.385 7.83.747c-10.568 5.645-9.227 28.308.932 28.308 1.937 0 3.123-.846 3.558-.846.435 0 3.07 1.291 5.68 1.291Z"
    }, null, -1)
  ])))
}
export default { render: render }