import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "22",
  height: "18",
  fill: "none",
  viewBox: "0 0 22 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M6.212 5.04a1.941 1.941 0 0 0-.088-.002c-.457 0-.897.197-1.894 1.757-1.06 1.659-.94 2.028-.65 2.364.29.336.702.581 3.296.696.488.022.897.032 1.24.032 1.48 0 1.74-.187 1.903-.442.201-.314.22-.677-1.314-2.451C7.171 5.219 6.703 5.062 6.212 5.04Zm.73 3.317c-.907-.04-1.469-.095-1.815-.145.089-.16.208-.36.367-.61.365-.57.597-.86.729-1.003.178.131.571.477 1.347 1.375.132.152.247.29.35.412a29.76 29.76 0 0 1-.977-.03Zm8.934-3.319c-.03 0-.058.001-.088.003-.491.021-.96.178-2.493 1.953-1.534 1.774-1.515 2.137-1.314 2.45.162.256.423.443 1.903.443.343 0 .751-.01 1.24-.032 2.594-.115 3.006-.36 3.296-.696.29-.336.41-.705-.65-2.364-.997-1.56-1.437-1.757-1.894-1.757Zm-.819 3.319c-.365.016-.692.026-.976.03.102-.124.217-.26.349-.413.776-.897 1.169-1.244 1.347-1.375.132.143.365.433.729 1.004.16.25.279.45.367.609-.346.05-.908.105-1.816.145ZM17.393.153A1.326 1.326 0 0 0 16.77 0c-.247 0-.495.067-.721.192C15.1.717 13.16 1.617 11 1.617c-2.16 0-4.101-.9-5.05-1.425A1.494 1.494 0 0 0 5.23 0c-.213 0-.425.05-.622.153-6.642 3.504-5.8 17.57.586 17.57 1.218 0 1.963-.524 2.237-.524.273 0 1.93.801 3.57.801s3.297-.801 3.57-.801c.274 0 1.02.525 2.237.525 6.385 0 7.228-14.067.586-17.57Zm-.586 16.07c-.54 0-.946-.16-1.273-.29-.29-.116-.589-.234-.964-.234-.33 0-.598.092-1.04.244-.605.209-1.616.557-2.53.557-.914 0-1.925-.348-2.53-.557-.442-.152-.71-.244-1.04-.244-.375 0-.674.118-.964.233-.327.13-.734.292-1.273.292-2.189 0-3.08-2.648-3.362-3.786-.96-3.871.186-9.16 3.412-10.924 1.094.604 3.28 1.603 5.757 1.603 2.476 0 4.661-.998 5.751-1.606 3.23 1.76 4.378 7.053 3.418 10.927-.281 1.138-1.173 3.786-3.362 3.786Z"
    }, null, -1)
  ])))
}
export default { render: render }