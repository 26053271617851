import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M19.445 6.085A7.07 7.07 0 0 0 19 3.775 4.86 4.86 0 0 0 16.225 1a7.07 7.07 0 0 0-2.31-.445C12.915.51 12.58.5 10 .5S7.095.5 6.085.555A7.07 7.07 0 0 0 3.775 1 4.86 4.86 0 0 0 1 3.775a7.07 7.07 0 0 0-.445 2.31C.51 7.085.5 7.42.5 10s0 2.905.055 3.915c.018.79.169 1.57.445 2.31A4.86 4.86 0 0 0 3.775 19c.74.276 1.52.427 2.31.445 1 .045 1.335.055 3.915.055s2.905 0 3.915-.055a7.068 7.068 0 0 0 2.31-.445A4.86 4.86 0 0 0 19 16.225a7.07 7.07 0 0 0 .445-2.31c.045-1 .055-1.335.055-3.915s0-2.905-.055-3.915ZM17.86 13.84a5.23 5.23 0 0 1-.325 1.765 3.785 3.785 0 0 1-1.93 1.93c-.565.21-1.162.32-1.765.325-1 .045-1.305.055-3.84.055s-2.835 0-3.84-.055a5.227 5.227 0 0 1-1.765-.325 3.785 3.785 0 0 1-1.93-1.93 5.23 5.23 0 0 1-.325-1.765c-.045-1-.055-1.305-.055-3.84s0-2.835.055-3.84a5.23 5.23 0 0 1 .325-1.765 3.785 3.785 0 0 1 1.93-1.93A5.23 5.23 0 0 1 6.16 2.14c1-.045 1.305-.055 3.84-.055s2.835 0 3.84.055c.603.006 1.2.116 1.765.325a3.785 3.785 0 0 1 1.93 1.93c.21.565.32 1.162.325 1.765.045 1 .055 1.305.055 3.84s-.01 2.835-.055 3.84ZM10 5.12a4.88 4.88 0 1 0 0 9.76 4.88 4.88 0 0 0 0-9.76Zm0 8.045a3.165 3.165 0 1 1 0-6.33 3.165 3.165 0 0 1 0 6.33Zm6.21-8.235a1.14 1.14 0 1 1-2.28 0 1.14 1.14 0 0 1 2.28 0Z"
    }, null, -1)
  ])))
}
export default { render: render }