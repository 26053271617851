import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M19.1599 5.90527C14.5999 8.48027 10.7399 12.9102 8.78492 15.4152C8.18492 14.5552 7.23992 13.3202 6.00992 12.0902L5.29992 11.3802L3.79492 12.8852L4.49992 13.5902C5.97492 15.0652 6.99992 16.5452 7.48492 17.2952C7.74992 17.7102 8.19992 17.9652 8.68992 17.9852C8.70992 17.9852 8.72492 17.9852 8.74492 17.9852C9.21492 17.9852 9.65992 17.7652 9.94492 17.3852C11.1199 15.8202 15.3249 10.5052 20.1999 7.75027L19.1599 5.90527Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }