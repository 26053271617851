import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "21",
  fill: "none",
  viewBox: "0 0 20 21"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M7 9h1.5v6.5H7V9Zm6 0h-1.5v6.5H13V9Zm7-3.445v1.5l-.22-.035c-.755-.085-1.5-.155-2.28-.22V13c0 1.402-.08 2.803-.245 4.195a3.5 3.5 0 0 1-3.11 3.075 37.461 37.461 0 0 1-8.29 0 3.5 3.5 0 0 1-3.11-3.075A35.638 35.638 0 0 1 2.5 13V6.82c-.765.065-1.525.135-2.28.22L0 7.075v-1.5h.055A90.23 90.23 0 0 1 5.01 5.16c0-.81.06-1.625.155-2.435a2.355 2.355 0 0 1 2.07-2.05c1.838-.2 3.692-.2 5.53 0a2.355 2.355 0 0 1 2.07 2.05c.095.81.14 1.625.155 2.435 1.66.095 3.315.23 4.955.415l.055-.02Zm-13.5-.5A90.38 90.38 0 0 1 10 4.98c1.175 0 2.325.03 3.5.075 0-.735-.06-1.47-.145-2.2a.845.845 0 0 0-.75-.73 23.295 23.295 0 0 0-5.19 0 .845.845 0 0 0-.75.73c-.095.75-.14 1.485-.165 2.22v-.02ZM16 6.69c-2-.135-4-.21-6-.21s-4 .075-6 .21V13c0 1.345.08 2.689.235 4.025A2.03 2.03 0 0 0 6.02 18.78a36.07 36.07 0 0 0 7.955 0 2.03 2.03 0 0 0 1.79-1.755A34.93 34.93 0 0 0 16 13V6.69Z"
    }, null, -1)
  ])))
}
export default { render: render }