import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "25",
  viewBox: "0 0 24 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M21.215 8.22609C20.995 6.33109 19.47 4.85109 17.585 4.64109C17.225 4.59609 16.865 4.56109 16.5 4.53109V1.87109H15V4.43109C14.25 4.39109 13.5 4.37109 12.75 4.37109H11.25C10.5 4.37109 9.75 4.39109 9 4.43109V1.87109H7.5V4.53109C7.135 4.56109 6.775 4.59609 6.415 4.63609C4.53 4.84609 3.005 6.33109 2.785 8.22109C2.405 11.4761 2.405 14.7611 2.785 18.0161C3.005 19.9111 4.53 21.3911 6.415 21.6011C8.01 21.7811 9.63 21.8711 11.25 21.8711H12.75C19.25 21.8711 21.5 19.6211 21.5 13.1211C21.5 11.4861 21.405 9.85109 21.215 8.22609ZM4.275 17.8411C3.91 14.7061 3.91 11.5311 4.275 8.39609C4.415 7.21609 5.385 6.26109 6.58 6.12609C8.11 5.95609 9.68 5.87109 11.25 5.87109H12.75C14.315 5.87109 15.885 5.95609 17.42 6.12609C18.62 6.26109 19.59 7.21609 19.725 8.39609C19.84 9.40109 19.92 10.4161 19.96 11.4261C19.945 13.4561 19.08 13.7511 17.755 14.2061C16.935 14.4861 16 14.8061 15.22 15.5861C14.435 16.3711 14.12 17.2861 13.845 18.0911C13.39 19.4111 13.07 20.3211 10.75 20.3611C9.35 20.3461 7.95 20.2611 6.585 20.1111C5.385 19.9811 4.415 19.0261 4.275 17.8411ZM14.47 20.3061C14.845 19.7711 15.06 19.1661 15.26 18.5811C15.505 17.8661 15.74 17.1861 16.275 16.6511C16.81 16.1161 17.505 15.8811 18.235 15.6311C18.795 15.4411 19.395 15.2261 19.93 14.8611C19.64 18.6011 18.225 20.0161 14.47 20.3061Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }