import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "18",
  fill: "currentColor",
  viewBox: "0 0 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M5.175 12.725H.725c.085-.56 1.2-7.625 1.27-8.085h.82c.53-.005 1.025-.25 1.255-.625.22-.365.135-.75-.22-1.02-.12-.09-.255-.235-.23-.41.04-.24.445-.57 1.02-.57.34 0 .61.075.745.205.095.09.13.21.105.345-.03.13-.17.3-.395.455-.62.44-.515.89-.465 1.02.14.385.625.595 1.005.595h.805L5.69 9.37h.015c-.285 1.77-.49 3.105-.53 3.355ZM11.64.5H7.11l-.605 3.83h.77c.42 0 .82-.195.99-.47a.47.47 0 0 0 .08-.245c0-.16-.11-.28-.215-.36-.295-.22-.36-.455-.36-.62 0-.035 0-.065.005-.09.065-.405.61-.84 1.335-.84.435 0 .765.1.965.29a.68.68 0 0 1 .195.645c-.065.29-.355.53-.52.65-.44.305-.38.575-.355.655.09.24.44.395.705.395h1.17v.005c1.595.01 2.45.745 2.18 2.45-.25 1.585-1.47 2.27-2.925 2.28L9.95 12.74h.85c3.585 0 6.515-2.3 7.09-5.93C18.605 2.3 15.745.5 11.64.5ZM3.21 15.11c-.045.29-.245.505-.585.65.435.165.605.42.54.825-.08.505-.52.88-1.095.88H0l.485-3.105h1.82c.77 0 .96.39.905.75Zm-1.55 1.015H1.3l-.135.87h.33v-.005c.365 0 .575-.15.625-.45.04-.265-.11-.415-.46-.415Zm.15-1.295H1.5l-.125.815h.315c.355 0 .52-.19.555-.41.03-.24-.08-.405-.435-.405Zm4.175 2.02-.275.635c-.475.035-.7-.015-.815-.22a1.55 1.55 0 0 1-.825.235c-.535 0-.725-.28-.67-.585.025-.15.11-.29.245-.41.295-.255 1.025-.29 1.31-.485.025-.215-.065-.295-.33-.295-.315 0-.575.1-1.025.41l.11-.705c.385-.28.765-.41 1.195-.41.555 0 1.045.23.95.83l-.11.685c-.04.235-.03.31.24.315Zm-1.095-.43c-.25.16-.72.13-.77.46-.025.155.075.265.23.265.15 0 .33-.065.48-.165a.695.695 0 0 1 .01-.22l.05-.34Zm3.425-1.4c-.295 0-.525.095-.825.355l.05-.315h-.945l-.37 2.395h.945l.215-1.405c.13-.175.27-.255.415-.255.18 0 .255.115.215.355l-.205 1.315h.945L9 15.88c.09-.535-.155-.86-.685-.86Zm3.035.04-.905.825.24-1.525H9.74l-.485 3.1h.945l.17-1.11h.01l.72 1.11h1.19l-.935-1.375 1.16-1.025H11.35Zm1.26 2.4h1.09l.48-3.1h-1.09l-.48 3.1Zm5.085-1.51c-.11.705-.665 1.51-1.72 1.51H14.22l.485-3.1h1.555c1.205 0 1.55.87 1.435 1.59Zm-1.725-.855h-.29l-.25 1.625h.285c.53 0 .82-.26.905-.815.065-.41-.06-.81-.65-.81Z"
    }, null, -1)
  ])))
}
export default { render: render }