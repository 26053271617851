import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "7",
  fill: "none",
  viewBox: "0 0 10 7"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m.545 1.73 3.77 4.455a.9.9 0 0 0 1.375 0L9.46 1.73A.748.748 0 0 0 8.89.5H1.115a.745.745 0 0 0-.57 1.23Z"
    }, null, -1)
  ])))
}
export default { render: render }