import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9.54 12.835a1.322 1.322 0 0 1-.015-1.63c.655-.83 1.37-1.635 2.12-2.38L15.4 5.07 14.34 4l-3.755 3.755c-.795.795-1.54 1.63-2.235 2.515a2.824 2.824 0 0 0 .02 3.5c.685.86 1.43 1.69 2.215 2.475L14.34 20l1.06-1.06-3.755-3.755c-.75-.75-1.455-1.54-2.105-2.35Z"
    }, null, -1)
  ])))
}
export default { render: render }