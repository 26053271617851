import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pointer-events-none relative right-1 z-10 col-start-1 row-start-1 size-4 self-center justify-self-end forced-colors:hidden",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M7.54511 10.73L11.3151 15.185C11.6751 15.61 12.3301 15.61 12.6901 15.185L16.4601 10.73C16.8701 10.245 16.5251 9.5 15.8901 9.5H8.11511C7.47511 9.5 7.13011 10.245 7.54511 10.73Z",
      fill: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }