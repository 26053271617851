import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "25",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", null, [
      _createElementVNode("path", {
        id: "Switch",
        d: "M17.7551 17.88L19.0901 18.65C18.6701 19.8 17.6251 20.65 16.3701 20.79C15.0951 20.93 13.7951 21 12.5001 21C11.2051 21 9.90512 20.93 8.63012 20.785C7.12012 20.615 5.90512 19.43 5.72512 17.915C5.57512 16.615 5.50012 15.31 5.50012 14V13.51C5.15012 13.81 4.80512 14.12 4.48012 14.45L3.55512 15.375L2.49512 14.315C2.89512 13.915 3.25012 13.56 3.42012 13.39C3.94512 12.865 4.49512 12.37 5.07012 11.915C5.41012 11.64 5.83012 11.5 6.24512 11.5C6.65512 11.5 7.06012 11.63 7.40012 11.9C7.98512 12.365 8.54512 12.86 9.07512 13.39C9.24512 13.56 9.60012 13.915 10.0001 14.315L8.94012 15.375L8.01512 14.45C7.69012 14.125 7.35012 13.82 7.00012 13.52V14C7.00012 15.245 7.07512 16.505 7.22012 17.745C7.31512 18.555 7.98012 19.205 8.80012 19.3C10.0151 19.43 11.2601 19.5 12.5001 19.5C13.7401 19.5 14.9851 19.43 16.2001 19.295C16.9751 19.21 17.6051 18.62 17.7551 17.88ZM21.4401 8.625L20.5151 9.55C20.1901 9.875 19.8451 10.185 19.4951 10.485V10C19.4951 8.69 19.4201 7.385 19.2651 6.08C19.0901 4.565 17.8701 3.38 16.3601 3.21C15.0951 3.07 13.7951 3 12.5001 3C11.2051 3 9.90512 3.07 8.63012 3.215C7.37512 3.355 6.33512 4.205 5.91012 5.355L7.24512 6.125C7.39512 5.38 8.02512 4.795 8.79512 4.705C10.0151 4.57 11.2601 4.5 12.5001 4.5C13.7401 4.5 14.9851 4.57 16.2001 4.705C17.0201 4.795 17.6851 5.45 17.7801 6.26C17.9251 7.495 18.0001 8.755 18.0001 10V10.48C17.6501 10.18 17.3051 9.87 16.9801 9.55L16.0551 8.625L14.9951 9.685C15.3951 10.085 15.7501 10.44 15.9201 10.61C16.4501 11.14 17.0101 11.635 17.5951 12.1C17.9351 12.365 18.3401 12.5 18.7501 12.5C19.1701 12.5 19.5851 12.36 19.9301 12.085C20.5051 11.625 21.0551 11.13 21.5801 10.61C21.7501 10.44 22.1051 10.085 22.5051 9.685L21.4401 8.625Z",
        fill: "currentColor"
      })
    ], -1)
  ])))
}
export default { render: render }