import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M21.835 4.2a2.355 2.355 0 0 0-2.075-2.05 25.5 25.5 0 0 0-5.525 0 2.355 2.355 0 0 0-2.07 2.05 23.84 23.84 0 0 0 0 5.6 2.354 2.354 0 0 0 2.07 2.05c1.836.2 3.689.2 5.525 0a2.355 2.355 0 0 0 2.075-2.05c.22-1.86.22-3.74 0-5.6Zm-1.5 5.425a.845.845 0 0 1-.75.73 23.284 23.284 0 0 1-5.19 0 .845.845 0 0 1-.75-.73 22.496 22.496 0 0 1 0-5.25.845.845 0 0 1 .75-.73 23.294 23.294 0 0 1 5.19 0 .845.845 0 0 1 .75.73c.208 1.744.212 3.506.01 5.25h-.01ZM19.46 13.5h1.5c-.04 1.18-.125 2.36-.265 3.535a4.225 4.225 0 0 1-3.73 3.69 45.135 45.135 0 0 1-9.95 0 4.225 4.225 0 0 1-3.73-3.69 42.936 42.936 0 0 1 0-10.07 4.225 4.225 0 0 1 3.73-3.69c1.15-.13 2.31-.21 3.475-.25v1.5a42.29 42.29 0 0 0-3.305.235 2.74 2.74 0 0 0-2.41 2.375 41.58 41.58 0 0 0 0 9.72 2.74 2.74 0 0 0 2.41 2.375c3.194.353 6.416.353 9.61 0a2.74 2.74 0 0 0 2.41-2.375c.137-1.11.225-2.228.265-3.355h-.01Z"
    }, null, -1)
  ])))
}
export default { render: render }