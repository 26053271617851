import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M4.905 1.96a1.935 1.935 0 0 1 1.77 1.125L7.45 4.75c.425.85-.06 1.79-.605 2.335l-.37.365c-.81.8-1.585 1.565-1.91 2.5a18.59 18.59 0 0 1-2.56-5.245 1.5 1.5 0 0 1 .59-1.67L3.81 2.23a1.92 1.92 0 0 1 1.075-.33l.02.06ZM14.5 12.305a1.5 1.5 0 0 1 .69.165l1.665.775a1.95 1.95 0 0 1 .795 2.845l-.805 1.215a1.5 1.5 0 0 1-1.67.59 18.587 18.587 0 0 1-5.245-2.56c.955-.325 1.72-1.1 2.5-1.91l.365-.37a2.45 2.45 0 0 1 1.645-.77l.06.02ZM4.825.38a3.43 3.43 0 0 0-1.91.58l-1.21.81a3 3 0 0 0-1.2 3.34A22.1 22.1 0 0 0 14.73 19.335a3 3 0 0 0 .86.125 2.97 2.97 0 0 0 2.5-1.325l.81-1.21a3.454 3.454 0 0 0-1.41-5.04l-1.67-.775a2.97 2.97 0 0 0-1.36-.325 3.915 3.915 0 0 0-2.705 1.21c-1.105 1.105-1.865 2-2.795 2-.56 0-1.18-.325-2-1.12-2.125-2.12-.885-3 .88-4.77 1.065-1.06 1.595-2.655.885-4.065L7.95 2.37a3.45 3.45 0 0 0-3.13-2l.005.01Z"
    }, null, -1)
  ])))
}
export default { render: render }