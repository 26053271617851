import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "22",
  height: "20",
  fill: "none",
  viewBox: "0 0 22 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M11 0a10 10 0 0 0-1.56 19.88v-7H6.9V10h2.54V7.795c0-2.5 1.5-3.89 3.775-3.89.75.01 1.5.076 2.24.195v2.46H14.19c-1.24 0-1.63.775-1.63 1.565V10h2.775l-.44 2.89H12.56v7A10 10 0 0 0 11 0Z"
    }, null, -1)
  ])))
}
export default { render: render }