import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "25",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", null, [
      _createElementVNode("path", {
        id: "Log Out",
        d: "M21.5998 13.1551C21.1321 13.7415 20.6313 14.3007 20.0998 14.83L17.4299 17.5L16.3699 16.44L17.9899 14.82L19.0399 13.7701C19.3649 13.4451 19.6748 13.1001 19.9698 12.7701H9.96983V11.2701H19.9698C19.6698 10.9201 19.3599 10.5801 19.0349 10.2701L16.3649 7.60002L17.4249 6.54008L20.0948 9.21C20.6253 9.73151 21.1261 10.2824 21.5948 10.86C21.849 11.1883 21.9872 11.5915 21.9881 12.0066C21.989 12.4218 21.8525 12.8257 21.5998 13.1551ZM7.19487 19.235C6.58527 19.1661 6.01641 18.8946 5.57945 18.464C5.14249 18.0334 4.86268 17.4686 4.78484 16.86C4.40483 13.6312 4.40483 10.3689 4.78484 7.14006C4.86268 6.53153 5.14249 5.96667 5.57945 5.53605C6.01641 5.10544 6.58527 4.83398 7.19487 4.76506C8.79045 4.58845 10.3945 4.49997 11.9999 4.50004C12.4999 4.50004 12.9999 4.51007 13.4999 4.53007V3.03007C12.9999 3.03007 12.4999 3.00505 11.9999 3.00505C10.3294 3.00279 8.66005 3.09463 6.99986 3.28007C6.05323 3.3847 5.16973 3.80607 4.49266 4.47587C3.8156 5.14567 3.38472 6.02457 3.26988 6.97001C2.87488 10.3151 2.87488 13.695 3.26988 17.0401C3.38472 17.9855 3.8156 18.8644 4.49266 19.5342C5.16973 20.204 6.05323 20.6254 6.99986 20.73C8.66005 20.9155 10.3294 21.0073 11.9999 21.005C12.4999 21.005 12.9999 21.005 13.4999 20.98V19.48C12.9999 19.5 12.4999 19.51 11.9999 19.51C10.3944 19.5068 8.79026 19.415 7.19487 19.235Z",
        fill: "#0061FF"
      })
    ], -1)
  ])))
}
export default { render: render }