import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "21",
  height: "19",
  fill: "none",
  viewBox: "0 0 21 19"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M11.625 14c0 .62-.505 1.125-1.125 1.125S9.375 14.62 9.375 14s.505-1.125 1.125-1.125 1.125.505 1.125 1.125ZM9.75 6.25v4.5a.749.749 0 1 0 1.5 0v-4.5a.749.749 0 1 0-1.5 0Zm.75-4.21c.585 0 1.565 0 5.235 6.35 3.665 6.35 3.175 7.2 2.885 7.71-.295.51-.785 1.355-8.115 1.355s-7.83-.845-8.12-1.355c-.295-.51-.785-1.355 2.885-7.71 3.665-6.35 4.645-6.35 5.23-6.35Zm0-1.54c-1.44 0-2.76.525-6.57 7.12-3.81 6.595-3.605 8-2.885 9.25.72 1.25 1.84 2.13 9.455 2.13s8.73-.88 9.45-2.13c.72-1.25.925-2.655-2.885-9.25C13.26 1.025 11.94.5 10.5.5Z"
    }, null, -1)
  ])))
}
export default { render: render }