import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m11.06 10 7.97 7.97-1.06 1.06L10 11.06l-7.97 7.97-1.06-1.06L8.94 10 .97 2.03 2.03.97 10 8.94 17.97.97l1.06 1.06L11.06 10Z"
    }, null, -1)
  ])))
}
export default { render: render }