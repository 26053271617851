import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "transparent",
      d: "M12 2.5c-1.76 0-3.52.095-5.25.29-2.045.23-3.7 1.84-3.94 3.895-.415 3.53-.415 7.1 0 10.635.24 2.055 1.895 3.665 3.94 3.895a48.192 48.192 0 0 0 10.5-.005c2.045-.23 3.7-1.84 3.94-3.895.415-3.53.415-7.1 0-10.635-.24-2.055-1.895-3.665-3.94-3.895-1.73-.19-3.49-.285-5.25-.285Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M19.97 10.5a44.625 44.625 0 0 1-.27 6.64c-.155 1.34-1.26 2.425-2.615 2.575-1.67.19-3.38.285-5.085.285s-3.415-.095-5.085-.28c-1.36-.15-2.46-1.235-2.615-2.575-.4-3.41-.4-6.87 0-10.285.155-1.34 1.26-2.425 2.615-2.58A46.353 46.353 0 0 1 17 4.27V2.765a47.493 47.493 0 0 0-10.25.025c-2.045.23-3.7 1.84-3.94 3.895-.415 3.53-.415 7.1 0 10.635.24 2.055 1.895 3.665 3.94 3.895a48.192 48.192 0 0 0 10.5-.005c2.045-.23 3.7-1.84 3.94-3.895.265-2.265.36-4.54.285-6.815H19.97Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M11.54 15.395h-.045c-.42-.015-.81-.24-1.04-.595a22.921 22.921 0 0 0-3.01-3.74l-.565-.56L8 9.38l.56.56a24.66 24.66 0 0 1 2.995 3.655c1.74-2.27 5.77-7.08 10.555-9.785l.69-.39.78 1.38-.69.39c-4.91 2.775-9.14 8.12-10.32 9.69-.245.325-.63.515-1.03.515Z"
    }, null, -1)
  ])))
}
export default { render: render }