import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M13.435 2.41003L15.435 6.41003C15.5207 6.58494 15.6481 6.73614 15.8059 6.85034C15.9636 6.96454 16.1471 7.03817 16.34 7.06494L20.7548 7.70496C21.0503 7.74803 21.3279 7.87295 21.5561 8.06555C21.7843 8.25816 21.954 8.51087 22.0461 8.79492C22.1382 9.07898 22.1491 9.38312 22.0773 9.67297C22.0056 9.96282 21.8539 10.2268 21.6398 10.4351L18.445 13.55C18.3063 13.6869 18.2026 13.8551 18.1425 14.0404C18.0825 14.2258 18.0679 14.4228 18.1 14.615L18.8549 19.01C18.9059 19.3043 18.8735 19.6071 18.7614 19.8839C18.6493 20.1608 18.4618 20.4007 18.2204 20.5765C17.979 20.7524 17.6933 20.8571 17.3955 20.8789C17.0976 20.9007 16.7993 20.8387 16.5349 20.7L12.5849 18.62C12.4115 18.5313 12.2197 18.4851 12.025 18.4851C11.8303 18.4851 11.6383 18.5313 11.4649 18.62L7.51496 20.7C7.25048 20.8387 6.95238 20.9007 6.65449 20.8789C6.3566 20.8571 6.07084 20.7524 5.82942 20.5765C5.58799 20.4007 5.40052 20.1608 5.2884 19.8839C5.17628 19.6071 5.14389 19.3043 5.19489 19.01L5.9499 14.615C5.982 14.4228 5.96746 14.2258 5.90742 14.0404C5.84737 13.8551 5.74361 13.6869 5.60493 13.55L2.40998 10.4351C2.19597 10.2268 2.04452 9.96282 1.97273 9.67297C1.90094 9.38312 1.91165 9.07898 2.00373 8.79492C2.09582 8.51087 2.26553 8.25816 2.49372 8.06555C2.72191 7.87295 2.99951 7.74803 3.29499 7.70496L7.70991 7.06494C7.90284 7.03817 8.0863 6.96454 8.24409 6.85034C8.40188 6.73614 8.52922 6.58494 8.61494 6.41003L10.6149 2.41003C10.7529 2.15642 10.9566 1.94464 11.2048 1.79712C11.4529 1.6496 11.7363 1.57178 12.025 1.57178C12.3137 1.57178 12.597 1.6496 12.8452 1.79712C13.0933 1.94464 13.2971 2.15642 13.435 2.41003Z"
    }, null, -1)
  ])))
}
export default { render: render }