import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "16",
  fill: "none",
  viewBox: "0 0 20 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M19.5 7.25H2.24l.03-.05c.655-.83 1.37-1.635 2.12-2.38l3.76-3.755L7.09 0 3.335 3.755C2.54 4.55 1.795 5.385 1.1 6.27a2.824 2.824 0 0 0 .02 3.5c.685.86 1.43 1.69 2.215 2.475L7.09 16l1.06-1.06-3.755-3.755c-.75-.75-1.455-1.54-2.105-2.35-.02-.025-.035-.055-.055-.085H19.5v-1.5Z"
    }, null, -1)
  ])))
}
export default { render: render }